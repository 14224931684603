import React, { useEffect, useState } from "react";

function Button({ icon, postId, buttonText, buttonType }) {
	const [permalink, setPermalink] = useState(null);

	useEffect(() => {
		if (postId) {
			const fetchPost = async () => {
				try {
					const response = await fetch(
						`${window.siteData.siteUrl}/wp-json/wp/v2/pages/${postId}`,
					);
					if (!response.ok) {
						throw new Error("Network response was not ok");
					}
					const post = await response.json();
					setPermalink(post.link);
				} catch (error) {
					console.error("Error fetching the post:", error);
				}
			};

			fetchPost();
		}
	}, [postId]);

	const baseClasses =
		"flex items-center justify-center px-6 py-4 text-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2";
	const buttonClasses = `${baseClasses} ${
		buttonType === "primary"
			? "bg-green-500 text-white hover:bg-green-400 focus-visible:outline-green-500"
			: buttonType === "secondary"
			? "bg-white text-black hover:bg-gray-300 focus-visible:outline-gray-500"
			: "bg-gray-200 text-black hover:bg-gray-300 focus-visible:outline-gray-500"
	}`;

	return (
		<div className="flex">
			<a href={permalink ? permalink : "#"} className={buttonClasses}>
				<i className={`bi bi-${icon} text-2xl mr-4`}></i>
				{buttonText}
			</a>
		</div>
	);
}

export default Button;
