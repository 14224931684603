import React from "react";
import ButtonRegister from "./ButtonRegister";

function ContactCard() {
	return (
		<div className="shadow-md bg-stone-100 sticky top-12">
			<img
				alt=""
				src={`${window.siteData.siteUrl}/wp-content/uploads/young_business_people-scaled.jpeg`}
			/>

			<div className="p-4">
				<h2 className="text-2xl font-bold text-gray-900 md:text-3xl">
					Are you seeking Career Training?
				</h2>

				<p className="hidden text-gray-500 md:mt-4 md:block">
					Register now to discover if you are eligible for our funded training
					programs and services.
				</p>

				<div className="flex mt-8 items-center w-full text-center">
					<ButtonRegister href="#modal-register" buttonText="Apply Now!" />
				</div>
			</div>
		</div>
	);
}

export default ContactCard;
