import React from "react";
import ReactDOM from "react-dom/client";

import MakerBlocks from "./MakerBlocks";
import WordPressLocalize from "./WordPressLocalize";
import CollapsePanel from "./CollapsePanel";
import ModalNoScroll from "./ModalNoScroll";
import ContactTabs from "./ContactTabs";

// Components
import ButtonRegister from "./components/ButtonRegister";
import Button from "./components/Button";
import ContactCard from "./components/ContactCard";
import SearchForm from "./components/SearchForm";
import SocialIcons from "./components/SocialIcons";

// Initialize main scripts
new WordPressLocalize();
new MakerBlocks();
new CollapsePanel();
new ModalNoScroll();

document.addEventListener("DOMContentLoaded", () => {
	try {
		console.log("DOM fully loaded and parsed.");

		const renderComponents = (selector, Component, getProps = () => ({})) => {
			const elements = document.querySelectorAll(selector);
			if (elements.length > 0) {
				console.log(`Found ${elements.length} ${selector} elements.`);
				elements.forEach((element, index) => {
					const props = getProps(element, index);
					console.log(
						`Rendering ${Component.name} component in element ${index + 1}.`,
					);
					ReactDOM.createRoot(element).render(<Component {...props} />);
				});
				console.log(`All ${Component.name} components rendered successfully.`);
			} else {
				console.error(`No elements with the selector '${selector}' found.`);
			}
		};

		renderComponents(".button-about-us", Button, (element) => ({
			icon: "info-circle-fill",
			postId: 7,
			buttonText: "Learn More About Us",
			buttonType: "primary",
		}));

		renderComponents(".button-view-courses", Button, (element) => ({
			icon: "mortarboard-fill",
			postId: 155,
			buttonText: "View All Courses",
			buttonType: "secondary",
		}));

		renderComponents(".button-register", ButtonRegister, (element) => ({
			href: "#modal-register",
			buttonText: "Apply Now!",
		}));

		renderComponents(".contact-card", ContactCard);

		renderComponents(".search-form", SearchForm);

		renderComponents(".social-contact-loop", SocialIcons);

		// You can add other components here as needed
	} catch (error) {
		console.error(
			"An error occurred while rendering the React components:",
			error,
		);
	}
});
