import React, { useEffect, useState } from "react";

function SocialIcons() {
	const [socialIcons, setSocialIcons] = useState([]);

	useEffect(() => {
		const fetchSocialIcons = async () => {
			try {
				const response = await fetch(
					`${window.siteData.restUrl}wp/v2/contact-methods`,
				);
				const data = await response.json();
				console.log(data);
				setSocialIcons(data);
			} catch (error) {
				console.error("Error fetching social icons:", error);
			}
		};

		fetchSocialIcons();
	}, []);

	if (socialIcons.length === 0) {
		return null; // Render nothing if no posts found
	}

	return (
		<ul className="flex space-x-4">
			{socialIcons.map((icon) => (
				<li key={icon.id}>
					<a href={icon.acf.contact_method_url}>
						<i className={icon.acf.contact_method_icon}></i>
					</a>
				</li>
			))}
		</ul>
	);
}

export default SocialIcons;
