import React from "react";

function SearchForm() {
	return (
		<form
			method="get"
			action={window.siteData.siteUrl}
			className="flex text-black"
		>
			<input name="s" id="s" type="search" placeholder="Search..." />
			<button className="px-4 bg-green-500">
				<i className="text-white bi-search"></i>
			</button>
		</form>
	);
}

export default SearchForm;
