import React from "react";

function ButtonRegister({ href, buttonText }) {
	return (
		<div>
			<a
				href={href}
				className="block bg-green-900 hover:bg-green-600 text-white text-center font-bold py-4 px-8"
			>
				{buttonText}
			</a>
		</div>
	);
}

export default ButtonRegister;
