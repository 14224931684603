class MakerBlocks {
  constructor() {
    this.init();
  }

  init() {
    console.log("Maker Blocks Main Script initialized");
  }
}

export default MakerBlocks;
